interface PluralizeSuffixes {
  singular: string;
  plural: string;
}

export function pluralize(
  word: string,
  count: number,
  suffixes: PluralizeSuffixes = { singular: '', plural: 's' },
) {
  return `${word}${count === 1 ? suffixes.singular : suffixes.plural}`;
}
