import { Box, Button, Card, Typography, useTheme } from '@mui/material';
import Link from 'next/link';
import React from 'react';

export type CTAProps = {
  text: React.ReactNode;
  image: React.ReactNode;
  greyBackground?: boolean;
} & (
  | { buttonText: string; buttonHref: string; buttonOnClick?: never }
  | { buttonText: string; buttonHref?: never; buttonOnClick: () => void }
  | { buttonText?: never; buttonHref?: never; buttonOnClick?: never }
);

export default function CTA({
  text,
  buttonText,
  buttonHref,
  buttonOnClick,
  image,
  greyBackground = false,
}: CTAProps) {
  const theme = useTheme();
  const isInternalHref = buttonHref?.startsWith('/');

  return (
    <Card sx={{ backgroundColor: greyBackground ? theme.palette.grey[100] : null }}>
      <Box
        flexDirection={{ xs: 'column', md: 'row' }}
        gap={{ xs: 2, md: 3 }}
        alignItems={{ md: 'center' }}
        display="flex"
        justifyContent="space-between"
        p={3}
      >
        <Box display="flex" alignItems="center" gap={3}>
          <Box flexShrink={0} display="flex">
            {image}
          </Box>
          <Typography variant="body3">{text}</Typography>
        </Box>
        {buttonText && buttonHref && (
          <>
            {isInternalHref ? (
              <Button
                color="primary"
                sx={{ width: { xs: '100%', md: 'auto' } }}
                href={buttonHref}
                component={Link}
              >
                {buttonText}
              </Button>
            ) : (
              <Button
                color="primary"
                sx={{ width: { xs: '100%', md: 'auto' } }}
                href={buttonHref}
                target="_blank"
                rel="noreferrer nofollow"
              >
                {buttonText}
              </Button>
            )}
          </>
        )}
        {buttonOnClick && (
          <Button
            color="primary"
            sx={{ width: { xs: '100%', md: 'auto' } }}
            onClick={buttonOnClick}
          >
            {buttonText}
          </Button>
        )}
      </Box>
    </Card>
  );
}
