import { Ref, forwardRef } from 'react';
import { Button, ButtonProps } from '@mui/material';
import Link, { LinkProps } from 'next/link';

export type MoButtonLinkProps = Omit<ButtonProps, 'href'> & Pick<LinkProps, 'href'>;

const MoButtonLink = ({ href, ...props }: MoButtonLinkProps, ref: Ref<HTMLButtonElement>) => {
  return (
    <Link href={href} passHref legacyBehavior>
      <Button ref={ref} {...props} />
    </Link>
  );
};

export default forwardRef<HTMLButtonElement, MoButtonLinkProps>(MoButtonLink);
