export enum CourseTypeEnum {
  ON_DEMAND = 0,
  LIVE = 1,
}

export enum FormQuestionTypesEnum {
  MULTIPLE_CHOICE = 1,
  FREE_TEXT = 2,
  TRUE_FALSE = 3,
  LIKERT_SCALE = 4,
}
