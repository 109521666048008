import { gql } from '@apollo/client';

export const coursesQuery = gql`
  query CoursesQuery(
    $limit: Int!
    $offset: Int!
    $flagship: Boolean
    $courseTypeId: Int
    $licenseCategoryId: Int
    $creditHours: Float
    $topicId: Int
    $accreditationId: String
  ) {
    courses(
      limit: $limit
      offset: $offset
      flagship: $flagship
      courseTypeId: $courseTypeId
      topicId: $topicId
      creditHours: $creditHours
      licenseCategoryId: $licenseCategoryId
      accreditationId: $accreditationId
    ) {
      result {
        id
        name
        slug
        summary
        duration
        creditHours
        courseTypeId
        scheduledFor
        viewerEnrollment {
          id
        }
        thumbnailPath
      }
      total
    }
  }
`;

const continuingEducationSummaryFragment = gql`
  fragment ContinuingEducationSummaryFragment on ContinuingEducationSummaryResult {
    coursesCompletedCount
    creditsCompletedCount
    coursesInProgressCount
    upcomingLiveCourse {
      id
      name
      scheduledFor
      slug
    }
  }
`;

export const continuingEducationSummaryQuery = gql`
  query ContinuingEducationSummaryQuery {
    continuingEducationSummary {
      ...ContinuingEducationSummaryFragment
      inProgressOnDemandCourse {
        id
        name
        creditHours
        summary
        slug
        scheduledFor
        courseTypeId
        thumbnailPath
      }
    }
  }
  ${continuingEducationSummaryFragment}
`;

export const continuingEducationSummaryMyCoursesQuery = gql`
  query ContinuingEducationSummaryMyCoursesQuery {
    continuingEducationSummary {
      ...ContinuingEducationSummaryFragment
    }
  }
  ${continuingEducationSummaryFragment}
`;

export const courseBySlugQuery = gql`
  query CourseBySlugQuery($slug: String!) {
    courseBySlug(slug: $slug) {
      id
      name
      slug
      summary
      description
      duration
      creditHours
      courseTypeId
      scheduledFor
      viewerEnrollment {
        id
        completedEvaluationAt
        completedMasteryAt
        completedCourseAt
        enrolledAsLive
      }
      courseCredentialingBodyApprovals {
        id
        approvalStatement
        credentialingBodyAccreditation {
          id
          name
        }
      }
      credentialingBodyAccreditations {
        id
        name
        approvalStatementTemplate
      }
      thumbnailPath
      courseContent {
        id
        hlsManifestUrl
      }
      panelists {
        id
        slug
        displayName
        avatarPath
      }
      licenseCategories {
        id
        displayName
      }
      topics {
        id
        name
      }
    }
  }
`;

const formFragment = gql`
  fragment FormFragment on Form {
    id
    publishedFormVersion {
      id
      passingPercentage
    }
    questions {
      ... on MultipleChoiceQuestion {
        id
        questionTypeId
        question
        sort
        choices {
          options {
            key
            value
          }
        }
      }
      ... on TrueFalseQuestion {
        id
        questionTypeId
        question
        sort
      }
      ... on FreeTextQuestion {
        id
        questionTypeId
        question
        sort
      }
      ... on LikertScaleQuestion {
        id
        questionTypeId
        question
        sort
        choices {
          from
          to
          toLabel
          fromLabel
        }
      }
    }
  }
`;

export const courseWithFormsBySlugQuery = gql`
  query CourseWithFormsBySlugQuery($slug: String!) {
    courseBySlug(slug: $slug) {
      id
      courseTypeId
      viewerEnrollment {
        id
        completedEvaluationAt
        completedMasteryAt
        completedCourseAt
        enrolledAsLive
      }
      evaluationForm {
        ...FormFragment
      }
      masteryForm {
        ...FormFragment
      }
    }
  }
  ${formFragment}
`;

export const courseEnrollmentBySlugQuery = gql`
  query CourseEnrollmentBySlugQuery($slug: String!) {
    courseBySlug(slug: $slug) {
      id
      courseTypeId
      viewerEnrollment {
        id
        completedEvaluationAt
        completedMasteryAt
        completedCourseAt
        enrolledAsLive
      }
    }
  }
`;

export const formResponseQuery = gql`
  query FormResponseQuery($formVersionId: String!, $enrollmentId: String!) {
    formResponse(formVersionId: $formVersionId, enrollmentId: $enrollmentId) {
      id
      scorePercentage
      passing
      answers {
        id
        formQuestionId
        value
      }
    }
  }
`;

export const enrolledCoursesQuery = gql`
  query EnrolledCoursesQuery(
    $limit: Int
    $offset: Int
    $completed: Boolean!
    $withEnrollment: Boolean = false
  ) {
    enrolledCourses(limit: $limit, offset: $offset, completed: $completed) {
      result {
        id
        name
        slug
        summary
        duration
        creditHours
        courseTypeId
        scheduledFor
        thumbnailPath
        viewerEnrollment @include(if: $withEnrollment) {
          id
          completedCourseAt
        }
      }
      total
    }
  }
`;

export const courseSearchLookupQuery = gql`
  query CourseSearchLookupQuery {
    licenseCategories {
      id
      displayName
    }
    topics {
      id
      name
    }
    credentialingBodyAccreditations {
      id
      name
    }
    creditHours
  }
`;

export const panelistBySlugQuery = gql`
  query PanelistBySlugQuery($slug: String!) {
    panelistBySlug(slug: $slug) {
      id
      firstName
      displayName
      avatarPath
      twitterUrl
      linkedinUrl
      instagramUrl
      facebookUrl
      websiteUrl
      bio
      courses {
        id
        name
        slug
        summary
        duration
        creditHours
        courseTypeId
        scheduledFor
        viewerEnrollment {
          id
        }
        thumbnailPath
      }
    }
  }
`;

export const panelistsQuery = gql`
  query PanelistsQuery {
    panelists {
      result {
        id
        slug
      }
    }
  }
`;
