import { styled, Typography, TypographyProps } from '@mui/material';

const LineClampTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'maxLines',
})<{ maxLines: number }>`
  display: -webkit-box;
  -webkit-line-clamp: ${({ maxLines }) => maxLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export type LineClampProps = {
  maxLines: number;
};

export default function LineClamp(props: TypographyProps & LineClampProps) {
  return <LineClampTypography {...props} />;
}
