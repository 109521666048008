'use client';

import React from 'react';
import { Breakpoint, styled } from '@mui/material/styles';

interface CSSGridItemBreakpoint {
  gridColumnEnd?: string;
  order?: number;
}

const Grid = styled('div', {
  shouldForwardProp: (prop) => prop === 'children',
})<MoGridProps>(({ theme, spacing = 3, container, item, ...breakpointProps }) => {
  let properties = {};
  if (container) {
    properties = {
      ...properties,
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gap: theme.spacing(spacing),
    };
  }
  if (item) {
    const itemProperties = (['xs', 'sm', 'md', 'lg', 'xl'] as Breakpoint[]).reduce(
      (accumulator, breakpoint) => {
        const breakpointProp = breakpointProps[breakpoint];
        const cssBreakpointProps: CSSGridItemBreakpoint = {};
        if (breakpointProp) {
          if (typeof breakpointProp === 'number') {
            cssBreakpointProps.gridColumnEnd = `span ${breakpointProp}`;
          } else {
            if (breakpointProp.order != null) {
              cssBreakpointProps.order = breakpointProp.order;
            }
            if (breakpointProp.columns != null) {
              cssBreakpointProps.gridColumnEnd = `span ${breakpointProp.columns}`;
            }
          }
          accumulator[theme.breakpoints.up(breakpoint)] = cssBreakpointProps;
        }
        return accumulator;
      },
      { [theme.breakpoints.up('xs')]: { gridColumnEnd: `span 12` } } as Record<
        string,
        CSSGridItemBreakpoint
      >,
    );
    properties = {
      ...properties,
      ...itemProperties,
    };
  }
  return properties;
});

export type MoGridBreakpointProp =
  | number
  | {
      order?: number;
      columns?: number;
    };

export type MoGridProps = {
  container?: true;
  item?: true;
  spacing?: number;
  xs?: MoGridBreakpointProp;
  sm?: MoGridBreakpointProp;
  md?: MoGridBreakpointProp;
  lg?: MoGridBreakpointProp;
  xl?: MoGridBreakpointProp;
  children: React.ReactNode;
};

export default function MoGrid({ children, ...props }: MoGridProps) {
  return <Grid {...props}>{children}</Grid>;
}
